import { REDWOOD_MFA } from "../../../routes";

type AllCredential = {
    name: string;
    status: string;
};

type SPData = {
    allCredentials: AllCredential[];
    credentials: string[];
    verificationOptions: string[];
};

type SPHeader = {
    SP_HEADER_VERSION: number;
    accountsMetaData: string[];
    authLevel: string;
    betaTester: boolean;
    csrf: string;
    developer: boolean;
    deviceName: string;
    isDelegate: boolean;
    personId: number;
    qualifiedLead: boolean;
    status: string;
    success: boolean;
    userGuid: string;
    userStage: string;
    username: string;
};

type RedwoodAuthenticateTokenResponseData = {
    data: {
        spData: SPData;
        spHeader: SPHeader;
    };
};
type NavigateFunction = (path: string) => void;

const loadFTD = (
    response: RedwoodAuthenticateTokenResponseData,
    navigate: NavigateFunction,
    state: any
) => {
    //Land on freeminum dashbaord page
    const spHeader = response.data.spHeader;
    if (spHeader && spHeader.authLevel == "SESSION_AUTHENTICATED" && spHeader.status == "ACTIVE") {
        window.csrf = spHeader.csrf;
        sessionStorage.setItem("userGuidToken", spHeader.userGuid);
        sessionStorage.setItem("authenticated", spHeader.username);
        const redirectUrl = "" + "/dashboard/#/home";
        (window as Window).location = redirectUrl;
    } else {
        window.csrf = spHeader.csrf;
        if (state) {
            state.go("redwoodMfa");
        } else {
            navigate(REDWOOD_MFA);
        }
    }
};

export default loadFTD;
