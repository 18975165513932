import { useNavigate } from "react-router-dom";
import { RedirectData, RedirectParams } from "../../services/redirect/types";
import getExternalRedirectUrl from "../../services/redirect/getExternalRedirectUrl";
import getSpanishRedirectUrl from "../../services/redirect/getSpanishRedirectUrl";
import getLegacyRedirectUrl from "../../services/redirect/getLegacyRedirectUrl";
import getInternalRedirectUrl from "../../services/redirect/getInternalRedirectUrl";

interface UseRedirectProps {
    featureName?: string;
    gaId?: string;
}

type UseRedirect = (data: RedirectData, params?: RedirectParams) => void;

const useRedirect = ({ featureName, gaId }: UseRedirectProps = {}): UseRedirect => {
    const navigate = useNavigate();
    const portalRedirectForm = document.querySelector<HTMLFormElement>("#portalRedirectForm");

    /**
     * Angular redirect needs "/" at beginning of url; Append if missing
     * @param data
     * @param params
     */
    const redirect = async (data: RedirectData, params?: RedirectParams) => {
        try {
            let url = getInternalRedirectUrl(data);
            if (String(url) > "" && String(url).substring(0, 1) !== "/") {
                url = "/" + url;
            }

            if (url) {
                // Redirect internally within login-ui
                if (data.deepLinkedUrl) {
                    params = {
                        ...params,
                        deepLinkedUrl: data.deepLinkedUrl,
                        plansMapWithIndid: data.plansMapWithIndid
                    };
                }
                navigate(url, { replace: true, state: params });
            } else {
                // Redirect externally to another application
                url = await getExternalRedirectUrl(data, featureName, gaId);
                if (data.languageCode === "SPN") {
                    url = await getSpanishRedirectUrl(url);
                }

                if (data.destinationUrl === "LEGACY_SSO_INFO" && portalRedirectForm) {
                    url = await getLegacyRedirectUrl(data.accessPortalGroups);
                    portalRedirectForm.setAttribute("action", url);
                    portalRedirectForm.submit();
                } else {
                    window.location.href = `../../${url}`;
                }
            }
        } catch (error) {
            console.error("Error while attempting to redirect", error);
            throw error;
        }
    };
    return redirect;
};

export default useRedirect;
