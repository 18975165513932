import "jquery";
import "bootstrap";
import "angular";
import "angular-animate";
import "angular-cookies";
import "angular-resource";
import "angular-ui-bootstrap";
import "angular-sanitize";
import "angular-touch";
import "angular-messages";
import "angular-ui-router";
import "angular-translate";
import "angular-translate-loader-url";
import "inputmask";
import "../../../client/libs/vendor/angular-mask/dist/ngMask.min.js";
import "cleave.js/src/Cleave.angular.js";

import AdminModule from "admin-ui";
import BeneficiaryModule from "beneficiary-ui/client/src/app/module/beneficiary/BeneficiaryModule";
import AccessibleTabs from "core-ui/client/src/app/AccessibleTabs";
import AlertMessagesDirective from "core-ui/client/src/app/AlertMessagesDirective";
import AriaLiveOrderDirective from "core-ui/client/src/app/AriaLiveOrderDirective";
import ContactModalModule from "core-ui/client/src/app/ContactModalModule";
import CoreTemplates from "core-ui/client/src/app/CoreTemplates";
import DynamicArticleRoutingModule from "core-ui/client/src/app/DynamicArticleRoutingModule";
import DynamicElectionRoutingModule from "core-ui/client/src/app/DynamicElectionRoutingModule";
import errorModule from "core-ui/client/src/app/ErrorModule";
import EventBusModule from "core-ui/client/src/app/EventBusModule";
import FevaDisclosureDirective from "core-ui/client/src/app/FevaDisclosureDirective";
import FullHeight from "core-ui/client/src/app/FullHeight";
import GroupModule from "core-ui/client/src/app/GroupModule";
import httpExceptionInterceptorProvider from "core-ui/client/src/app/HttpExceptionInterceptorProvider";
import LayoutModule from "core-ui/client/src/app/LayoutModule";
import LoadingButton from "core-ui/client/src/app/LoadingButtonDirective";
import LoggerModule from "core-ui/client/src/app/LoggerModule";
import PaeModule from "core-ui/client/src/app/PaeModule";
import PageTitle from "core-ui/client/src/app/PageTitle";
import PersonalMessagingModule from "core-ui/client/src/app/PersonalMessagingModule";
import PrintDirective from "core-ui/client/src/app/PrintDirective";
import ScreenSize from "core-ui/client/src/app/ScreenSize";
import EDeliveryInlineViewContainer from "core-ui/client/react/core/components/eDelivery/EDeliveryInlineViewContainer";
// eslint-disable-next-line import/order
import SSOLoader from "./login/components/ssoLoader/QueryWrapper.tsx";
import TranslateServiceModule from "core-ui/client/src/app/TranslateServiceModule";
import OAuth from "./login/components/oauth/index";

//############################################################################
//REACT - BEGIN
//############################################################################

//Componets
import coreuiReactComponents from "core-ui/dist/core-ui-react-components";
import { react2angular } from "react18-react2angular";

import uiUtils from "../../../client/libs/vendor/angular-ui-utils/ui-utils.js";

import accountRecovery from "./accountRecovery/AccountRecoveryModule";
import accountSelection from "./accountSelection/AccountSelectionModule";
import accountSetup from "./accountSetup/AccountSetupModule";
import AlternateSiteDialogDirective from "./alternateSiteNotification/AlternateSiteDialogDirective";
import authentication from "./authentication/AuthenticationModule";
import PreLoginAccuRetrievalService from "./authentication/PreLoginAccuRetrievalService";
import BrowserNotificationModule from "./browserNotification/BrowserNotificationModule";
import DefaultCustomizationRetrService from "./core/DefaultCustomizationRetrService";
import focusDirectives from "./core/FocusDirectives";
import LoginUIMasterController from "./core/LoginUIMasterController";
import pscPathRetrievalFactory from "./core/PSCPathRetrievalFactory";
import redirectService from "./core/RedirectService";
import validationDirectives from "./core/ValidationDirectives";
import deepLink from "./deepLink/DeepLinkModule";
import eConsent from "./eConsent/EConsentModule";
import httpProvider from "./httpProviderConfig";
import idProof from "./idProof/IDProofModule";
import IndIdSelection from "./indIdSelection/IndIdSelectionModule";
import InvestmentInformationModule from "./investmentInformation/InvestmentInformationModule";
import iraConsent from "./iraConsent/IraConsentModule";
import mfa from "./mfa/MFAModule";
import npdi from "./npdi/NpdiModule";
import oauth from "./oauth/OAUTHModule";
import OneIdModule from "./oneid/OneIdModule";
import profile from "./profile/ProfileModule";
// Ira Pre-Account Openning
// Premier IRA new Account opening
import AccountOpenDetailsFormAngular from "./login/components/accountOpen/AccountOpenDetailsFormAngular";
import AccountOpenPersonalDetailsFormAngular from "./login/components/accountOpen/AccountOpenPersonalDetailsFormAngular";
import IdentityVerificationComponentWrapper from "./login/components/idProof/otp/IdentityVerificationComponentWrapper";
import NoCodeModalComponent from "./login/components/idProof/otp/NoCodeModalComponent";
import VerificationCodeEntryComponent from "./login/components/idProof/otp/VerificationCodeEntryComponent";
import IraConsentComponent from "./login/components/iraConsent/IraConsentComponent";
import LoginFormContainer from "./login/components/loginForm/container/LoginFormContainer";
import AuthorizedDevicesComponent from "./login/components/profile/AuthorizedDevicesComponent";
import BeneficiariesEditComponent from "./login/components/profile/BeneficiariesEditComponent";
import EConsentComponent from "./login/components/profile/EConsentComponent";
import HomeMailingAddressComponent from "./login/components/profile/HomeMailingAddressComponent";
import LinkablePlansComponent from "./login/components/profile/LinkablePlansComponent";
import ChangeCommunicationPreferenceComponent from "./login/components/profile/modals/ChangeCommunicationPreferenceComponent";
import ChangeHomeAddressComponent from "./login/components/profile/modals/ChangeHomeAddressComponent";
import ChangePasswordComponent from "./login/components/profile/modals/ChangePasswordComponent";
import ChangePersonalContactComponent from "./login/components/profile/modals/ChangePersonalContactComponent";
import ChangeUsernameComponent from "./login/components/profile/modals/ChangeUsernameComponent";
import PersonalContactInfoComponent from "./login/components/profile/PersonalContactInfoComponent";
import ProfileBreadcrumbComponent from "./login/components/profile/ProfileBreadcrumbComponent";
import UsernamePasswordComponent from "./login/components/profile/UsernamePasswordComponent";
import WorkEmailAddressComponent from "./login/components/profile/WorkEmailAddressComponent";
import IraPreAccountOpenning from "./login/components/registration/iraPreAccountOpenning";
import Registration from "./login/components/registration/Registration";

import reEnrollModule from "./reenroll/ReEnrollModule";
import registration from "./registration/RegistrationModule";
import routeStates from "./Route";
import beneficiaryRouteStates from "./routes/BeneficiaryRouteStates";
import MFA from "./login/components/mfa";
import LoginHelpOptions from "./login/components/accountRecovery/loginHelp";

import react2angularWrapper from "./react2angularWrapper";
import EnterpriseChatQueryWrapper from "./login/components/chat/EnterpriseChatQueryWrapper.tsx";
import LandingRedwoodMfaWrapper from "./login/components/redwoodMfa/LandingRedwoodMfaWrapper";

const {
    HeaderViewProviderComponent,
    FooterViewProviderComponent,
    HeaderPlanMessaging,
    HeaderNoLogin
} = coreuiReactComponents;

const application = angular.module("application", [
    /*
     * 3rd Party resources
     */
    "cleave.js",
    "ngAnimate",
    "ngCookies",
    "ngMask",
    "ngMessages",
    "ngResource",
    "ngSanitize",
    "ngTouch",
    "pascalprecht.translate",
    "ui.bootstrap",
    "ui.router",
    /*
     * Features
     */
    AccessibleTabs.name,
    BrowserNotificationModule.name,
    ContactModalModule.name,
    DynamicArticleRoutingModule.name,
    DynamicElectionRoutingModule.name,
    EventBusModule.name,
    FullHeight.name,
    GroupModule.name,
    IndIdSelection.name,
    InvestmentInformationModule.name,
    LayoutModule.name,
    LoadingButton.name,
    LoggerModule.name,
    OneIdModule.name,
    PaeModule.name,
    PageTitle.name,
    PersonalMessagingModule.name,
    ScreenSize.name,
    TranslateServiceModule.name,

    accountRecovery.name,
    accountSelection.name,
    accountSetup.name,
    authentication.name,
    deepLink.name,
    eConsent.name,
    errorModule.name,
    httpExceptionInterceptorProvider.name,
    idProof.name,
    iraConsent.name,
    mfa.name,
    npdi.name,
    oauth.name,
    profile.name,
    reEnrollModule.name,
    registration.name,
    uiUtils.name,
    validationDirectives.name,

    BeneficiaryModule.name,

    CoreTemplates.name,
    AdminModule.name
]);

application.config(routeStates);
application.config(beneficiaryRouteStates);
application.config(httpProvider);
application.config([
    "$sceDelegateProvider",
    function ($sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist([
            "self",
            "http://localhost:*/**",
            "https://*.retirementpartner.com/**",
            "http://*.retirementpartner.com/**",
            "http://*.empower-retirement.com/**",
            "https://*.empower-retirement.com/**"
        ]);
    }
]);
application.config([
    "$compileProvider",
    function ($compileProvider) {
        const isProd = globalThis.env === "prod";
        $compileProvider.debugInfoEnabled(!isProd);
    }
]);
application.factory("DefaultCustomizationRetrService", DefaultCustomizationRetrService);
application.factory("PreLoginAccuRetrievalService", PreLoginAccuRetrievalService);
application.factory("redirectService", redirectService);
application.factory("pscPathRetrievalFactory", pscPathRetrievalFactory);
application.directive("focusDirectives", focusDirectives);
application.directive("printDirective", PrintDirective);
application.directive("srOrder", AriaLiveOrderDirective);
application.directive("alertMessage", AlertMessagesDirective);
application.directive("pwFevaDisclosure", FevaDisclosureDirective);
application.directive("alternateSiteDialog", AlternateSiteDialogDirective);
application.directive("typeaheadShowOnFocus", function () {
    return {
        require: "ngModel",
        link: function ($scope, element, attrs, ngModel) {
            element.bind("focus", function () {
                const viewValue = ngModel.$viewValue;

                //restore to null value so that the typeahead can detect a change
                if (viewValue === "") {
                    ngModel.$setViewValue(null);
                }

                //set the actual value in case there was already a value in the input
                ngModel.$setViewValue(viewValue || "");
            });
            element.bind("click", function () {
                const viewValue = ngModel.$viewValue;

                //restore to null value so that the typeahead can detect a change
                if (viewValue === "") {
                    ngModel.$setViewValue(null);
                }

                //set the actual value in case there was already a value in the input
                ngModel.$setViewValue(viewValue || "");
            });
        }
    };
});

//############################################################################
//REACT (Directives)- BEGIN
//############################################################################

/* Header & Footer */
application.component("pwrSsoLoaderComponent", react2angular(SSOLoader, []));
application.component("headerProvider", react2angular(HeaderViewProviderComponent, ["options"]));
application.component("footerProvider", react2angular(FooterViewProviderComponent, ["options"]));
application.component("headerNoLogin", react2angular(HeaderNoLogin, ["accuCustomization", "urls"]));
application.component(
    "headerPlanMessaging",
    react2angular(HeaderPlanMessaging, [
        "eventBus",
        "navMenu",
        "onViewAllPrevent",
        "prevent",
        "slides",
        "translations",
        "watchDepth"
    ])
);
/* Chat */
application.component("enterpriseChat", react2angular(EnterpriseChatQueryWrapper));

/* My profile */
application.component(
    "pwrOtpNocodeModalComponent",
    react2angular(NoCodeModalComponent, ["options"])
);
application.component(
    "pwrOtpIdentityVerificationComponent",
    react2angular(IdentityVerificationComponentWrapper, ["options", "isOtsUser", "flowName"])
);
application.component(
    "pwrOtpIdentityVerificationCodeEntryComponent",
    react2angular(VerificationCodeEntryComponent, [
        "options",
        "noCodeFunction",
        "isOtsUser",
        "flowName"
    ])
);

application.component(
    "pwrWorkEmailAddressComponent",
    react2angular(WorkEmailAddressComponent, ["profileInfo"])
);
application.component(
    "pwrLinkablePlansComponent",
    react2angular(LinkablePlansComponent, ["profileInfo", "openLinkablePlansPage"])
);

application.component(
    "pwrPersonalContactInfoComponent",
    react2angular(PersonalContactInfoComponent, ["profileInfo", "onClickFunction"])
);

application.component(
    "pwrHomeMailingAddressComponent",
    react2angular(HomeMailingAddressComponent, [
        "country",
        "zipcode",
        "city",
        "state",
        "stline1",
        "stline2",
        "isAddrUpdateAllowed",
        "pinAuthCode",
        "openHomeAddressModal"
    ])
);

application.component(
    "pwrUsernamePasswordComponent",
    react2angular(UsernamePasswordComponent, [
        "isUserNameUpdateAllowed",
        "pinAuthCode",
        "userName",
        "openUsernameModal",
        "openPasswordModal"
    ])
);

application.component(
    "pwrChangeUsernameComponent",
    react2angular(ChangeUsernameComponent, ["cancel", "updateUserName", "componentValue"])
);

application.component(
    "pwrChangePasswordComponent",
    react2angular(ChangePasswordComponent, [
        "cancel",
        "updatePassWord",
        "saveStatus",
        "saveStatusParams",
        "error"
    ])
);
application.component(
    "pwrEConsentComponent",
    react2angular(EConsentComponent, [
        "allowedToOverwrite",
        "displayChangeCommPrefsButton",
        "openModalTemplateCommunicationPreferences",
        "econsentemailAddress",
        "eConsentDetails"
    ])
);
application.component(
    "pwrChangeCommunicationPreferenceComponent",
    react2angular(ChangeCommunicationPreferenceComponent, [
        "primaryPlanT",
        "eConsentDetails",
        "saveStatus",
        "saveStatusParams",
        "updatePPTConsent",
        "updateEConsentDetail",
        "isStandalonePage",
        "email",
        "eConsent",
        "isModalWindow",
        "mustHaveEmail"
    ])
);
application.component(
    "pwrProfileBreadcrumbComponent",
    react2angular(ProfileBreadcrumbComponent, ["url"])
);
application.component(
    "pwrBeneficiariesEditComponent",
    react2angular(BeneficiariesEditComponent, ["plans", "pinAuthCode", "getBeneficiaryName"])
);

application.component(
    "pwrChangeHomeAddressComponent",
    react2angular(ChangeHomeAddressComponent, [
        "cancel",
        "updateHomeAddress",
        "saveStatus",
        "saveStatusParams",
        "stline1",
        "stline2",
        "city",
        "zipcode",
        "stateOptions",
        "countryOptions",
        "selectedCountryValue",
        "selectedStateValue"
    ])
);
application.component(
    "pwrChangePersonalContactComponent",
    react2angular(ChangePersonalContactComponent, [
        "cancel",
        "updatePersonalContact",
        "saveStatus",
        "saveStatusParams"
    ])
);
application.component(
    "pwrIraConsentComponent",
    react2angular(IraConsentComponent, ["groupId", "individualId", "goToNextLocation"])
);
application.component("pwrIraPreAccountOpenning", react2angular(IraPreAccountOpenning, []));

application.component("pwrReg", react2angularWrapper(Registration, []));

application.component(
    "pwrOtsAccountDetailsForm",
    react2angular(AccountOpenDetailsFormAngular, [
        "handleDetailsFormSubmit",
        "formData",
        "stateCodes",
        "hasReferrerParam"
    ])
);
application.component(
    "pwrOtsAccountPersonalDetailsForm",
    react2angular(AccountOpenPersonalDetailsFormAngular, [
        "handlePersonalDetailsFormSubmit",
        "formData",
        "isRegistered"
    ])
);
application.component(
    "pwrLoginForm",
    react2angularWrapper(LoginFormContainer, [
        "accu",
        "authentication",
        "authenticate",
        "loggingIn",
        "showLogin",
        "errorCode",
        "errorMessageParams",
        "credentials",
        "registerButtonClick",
        "openAccountClick"
    ])
);

application.component(
    "pwrAccountRecovery",
    react2angular(LoginHelpOptions, [], ["redirectService"])
);

application.component(
    "pwrAccountRecoveryMultipleUser",
    react2angular(LoginHelpOptions, [], ["redirectService"])
);

application.component(
    "pwrAccountRecoveryConfirmation",
    react2angularWrapper(LoginHelpOptions, [], [])
);

application.component("pwrRegisterInfo", react2angularWrapper(LoginHelpOptions, [], []));

application.component(
    "pwrEDeliveryInlineViewContainer",
    react2angular(EDeliveryInlineViewContainer, ["action", "eDeliveryInitData"])
);

application.component(
    "pwrMfa",
    react2angularWrapper(MFA, ["linkingContext"], ["redirectService", "$location", "$scope"])
);

application.component("pwrOauthLogin", react2angularWrapper(OAuth, []));

application.component("pwrOauthPreconsent", react2angularWrapper(OAuth, []));

application.component("pwrAuthorizedDevices", react2angular(AuthorizedDevicesComponent, []));

application.component(
    "pwrLoginHelp",
    react2angularWrapper(LoginHelpOptions, ["accu"], ["redirectService", "$location", "$scope"])
);

application.component(
    "pwrLoginHelpOptions",
    react2angularWrapper(LoginHelpOptions, ["accu"], ["redirectService", "$location", "$scope"])
);
application.component(
    "pwrLoginHelpLetsTryElse",
    react2angularWrapper(LoginHelpOptions, ["accu"], ["redirectService", "$location", "$scope"])
);

application.component(
    "pwrLoginHelpNoRecord",
    react2angularWrapper(LoginHelpOptions, ["accu"], ["redirectService", "$location", "$scope"])
);

application.component(
    "pwrNoPassword",
    react2angularWrapper(LoginHelpOptions, [], ["redirectService", "$location", "$scope"])
);

application.component(
    "pwrResetPasswordMfa",
    react2angularWrapper(LoginHelpOptions, [], ["redirectService", "$location", "$scope"])
);
application.component(
    "pwrResetPasswordMfaVerifyCode",
    react2angularWrapper(LoginHelpOptions, [], ["redirectService", "$location", "$scope"])
);
application.component(
    "pwrResetPasswordCreatePassword",
    react2angularWrapper(LoginHelpOptions, [], ["redirectService", "$location", "$scope"])
);

application.component(
    "pwrRegistrationSummary",
    react2angularWrapper(LoginHelpOptions, [], ["redirectService", "$location", "$scope"])
);

application.controller("LoginUIMasterController", LoginUIMasterController);
application.component(
    "pwrLandingRedwoodMfaComponent",
    react2angular(LandingRedwoodMfaWrapper, [], ["redirectService"])
);

//############################################################################
//REACT (Directives)- END
//############################################################################
export default application;

// Bootstrap the application once we are sure the ACCU has been resolved
angular.element(document.querySelector("html")).ready(function () {
    const appName = "application";

    if (window.accuResolved === true) {
        angular.bootstrap(document, [appName]);
    } else if (window.accuWatch) {
        // Wait until accu is resolved
        window.accuWatch.watch(() => {
            angular.bootstrap(document, [appName]);
        });
    }
});
