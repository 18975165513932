import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import LoginHelpMultiOptions from "./LoginHelpMultiOptions";
import LoginHelp from "./LoginHelp";
import DeliveryOptions from "../../mfa/deliveryOptions/DeliveryOptions";
import VerificationCodeEntry from "../../mfa/verificationCodeEntry/VerificationCodeEntry";
import {
    ACCOUNT_RECOVERY,
    ACCOUNT_RECOVERY_MULTIPLE_USERS,
    ACCOUNT_RECOVERY_CONFIRMATION,
    LOGIN,
    LOGIN_HELP_OPTIONS,
    LOGIN_HELP,
    LOGIN_HELP_NO_PASSWORD,
    LOGIN_HELP_LETS_TRY_ELSE,
    LOGIN_HELP_NO_RECORD,
    LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS,
    LOGIN_HELP_RESET_PASSWORD_VERIFICATION_CODE,
    LOGIN_HELP_RESET_CREATE_PASSWORD,
    MFA_ACTIVATION_CODE_DELIVERY_OPTIONS,
    MFA_VERIFY_CODE,
    LOGIN_HELP_WITH_ACCU,
    REGISTER_INFO,
    REGISTRATION_SUMMARY,
    CONTACT_UPDATE
} from "../../../routes";
import NoPassword from "./NoPassword";
import LoginHelpLetsTryElse from "./LoginHelpLetsTryElse";
import LoginHelpResetPasswordDeliveryOptions from "./mfa/LoginHelpResetPasswordDeliveryOptions";
import LoginHelpResetPwdVerificationCode from "./mfa/LoginHelpResetPwdVerificationCode";
import LoginHelpResetCreatePassword from "./mfa/LoginHelpResetCreatePassword";
import LandingLoginContainer from "../../../container/LandingLoginContainer";
import LoginHelpNoRecord from "./LoginHelpNoRecord";
import ErrorPage from "../../../components/Application/ErrorPage";
import AccountRecoveryUpd from "../AccountRecoveryUpd";
import AccountRecoveryMultipleUserUpd from "../AccountRecoveryMultipleUserUpd";
import AccountRecoveryConfirmationUpd from "../AccountRecoveryConfirmationUpd";
import RegisterInfo from "../../accountSetup/RegisterInfo";
import ContactUpdate from "../../accountSetup/ContactUpdate";
import RegistrationSummary from "../../register/registrationSummary/RegistrationSummary";
import { TranslationsServiceProvider } from "../../../providers/TranslationsServiceProvider";
import LoginQueryClientProvider from "../../../providers/LoginQueryClientProvider";

interface LoginHelpOptionsProps {
    $location: { path: (path: string) => void };
    $scope: { $apply: () => void; $root: { featureName: string } };
    accu: string;
    redirectService: {
        redirect: (data: { destinationUrl?: string; state: string }, key: string) => void;
    };
}

/**
 * TODO: DELETE THIS COMPONENT WHEN ANGULAR IS REMOVED.
 * This is a temporary component that provides access to react router, react query, and the translations hook
 * while still allowing the new loginHelp components to be embedded in the Angular application.
 * Also temporarily pass the redirectService from the Angular app until that has been converted.
 */
const LoginHelpOptions = ({ accu, $location, $scope, redirectService }: LoginHelpOptionsProps) => {
    const isReact = window.location.href.indexOf("isReact") > -1;

    return (
        !isReact && (
            <LoginQueryClientProvider>
                <TranslationsServiceProvider>
                    <HashRouter>
                        <Routes>
                            <Route
                                path={LOGIN_HELP_OPTIONS}
                                element={
                                    <LoginHelpMultiOptions
                                        accu={accu}
                                        location={$location}
                                        scope={$scope}
                                    />
                                }
                            ></Route>

                            <Route
                                path={LOGIN_HELP}
                                element={<LoginHelp location={$location} scope={$scope} />}
                            ></Route>

                            <Route
                                path={LOGIN_HELP_WITH_ACCU}
                                element={<LoginHelp location={$location} scope={$scope} />}
                            ></Route>

                            <Route
                                path={LOGIN_HELP_NO_PASSWORD}
                                element={<NoPassword location={$location} scope={$scope} />}
                            ></Route>
                            <Route
                                path={LOGIN_HELP_LETS_TRY_ELSE}
                                element={
                                    <LoginHelpLetsTryElse
                                        accu={accu}
                                        location={$location}
                                        scope={$scope}
                                    />
                                }
                            ></Route>
                            <Route
                                path={LOGIN_HELP_RESET_PASSWORD_DELIVERY_OPTIONS}
                                element={
                                    <LoginHelpResetPasswordDeliveryOptions
                                        location={$location}
                                        scope={$scope}
                                    />
                                }
                            ></Route>
                            <Route
                                path={LOGIN_HELP_RESET_PASSWORD_VERIFICATION_CODE}
                                element={<LoginHelpResetPwdVerificationCode />}
                            ></Route>
                            <Route
                                path={LOGIN_HELP_RESET_CREATE_PASSWORD}
                                element={<LoginHelpResetCreatePassword />}
                            ></Route>
                            <Route
                                path={LOGIN}
                                element={<LandingLoginContainer isSSOLogin={false} />}
                                errorElement={<ErrorPage />}
                            ></Route>
                            <Route
                                path={MFA_ACTIVATION_CODE_DELIVERY_OPTIONS}
                                element={<DeliveryOptions />}
                            ></Route>
                            <Route path={MFA_VERIFY_CODE} element={<VerificationCodeEntry />} />
                            <Route
                                path={ACCOUNT_RECOVERY_MULTIPLE_USERS}
                                element={
                                    <AccountRecoveryMultipleUserUpd
                                        redirectService={redirectService}
                                    />
                                }
                                errorElement={<ErrorPage />}
                            ></Route>
                            <Route
                                path={ACCOUNT_RECOVERY}
                                element={<AccountRecoveryUpd redirectService={redirectService} />}
                                errorElement={<ErrorPage />}
                            ></Route>
                            <Route
                                path={ACCOUNT_RECOVERY_CONFIRMATION}
                                element={<AccountRecoveryConfirmationUpd />}
                                errorElement={<ErrorPage />}
                            ></Route>
                            <Route
                                path={REGISTER_INFO}
                                element={<RegisterInfo />}
                                errorElement={<ErrorPage />}
                            ></Route>
                            <Route
                                path={CONTACT_UPDATE}
                                element={<ContactUpdate />}
                                errorElement={<ErrorPage />}
                            ></Route>
                            <Route
                                path={REGISTRATION_SUMMARY}
                                element={<RegistrationSummary />}
                                errorElement={<ErrorPage />}
                            ></Route>
                            <Route
                                path={LOGIN_HELP_NO_RECORD}
                                element={<LoginHelpNoRecord location={$location} scope={$scope} />}
                            />
                        </Routes>
                    </HashRouter>
                </TranslationsServiceProvider>
            </LoginQueryClientProvider>
        )
    );
};

export default LoginHelpOptions;
